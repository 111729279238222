//==== Base ====//
  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icons-name !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

//==== CSS Icons ====//
  .ddl-icon {
    position: relative;
    width: 1.4rem;
    height: 2rem;

    &:before,
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }
    &:before {
      top: 0;
      border-width: 0 6px 5px 6px;
      border-color: transparent transparent color(grey) transparent;
    }
    &:after {
      bottom: 0;
      border-width: 5px 6px 0 6px;
      border-color: color(grey) transparent transparent transparent;
    }
  }

  .icon-user2:before {
    content: '\e903';
  }
  .icon-chevron-thin-left:before {
    content: '\e901';
  }
  .icon-chevron-thin-right:before {
    content: '\e902';
  }
  .icon-user3:before {
    content: '\e904';
  }
  .icon-home:before {
    content: '\e900';
  }
  .icon-pencil:before {
    content: '\e905';
  }
  .icon-image:before {
    content: '\e90d';
  }
  .icon-file-empty:before {
    content: '\e924';
  }
  .icon-folder-plus:before {
    content: '\e931';
  }
  .icon-cart:before {
    content: '\e93a';
  }
  .icon-phone:before {
    content: '\e942';
  }
  .icon-address-book:before {
    content: '\e944';
  }
  .icon-envelop:before {
    content: '\e945';
  }
  .icon-location:before {
    content: '\e947';
  }
  .icon-compass:before {
    content: '\e949';
  }
  .icon-printer:before {
    content: '\e954';
  }
  .icon-laptop:before {
    content: '\e957';
  }
  .icon-mobile:before {
    content: '\e958';
  }
  .icon-mobile2:before {
    content: '\e959';
  }
  .icon-tablet:before {
    content: '\e95a';
  }
  .icon-floppy-disk:before {
    content: '\e962';
  }
  .icon-user:before {
    content: '\e971';
  }
  .icon-search:before {
    content: '\e986';
  }
  .icon-zoom-in:before {
    content: '\e987';
  }
  .icon-zoom-out:before {
    content: '\e988';
  }
  .icon-key:before {
    content: '\e98d';
  }
  .icon-menu:before {
    content: '\e9bd';
  }
  .icon-link:before {
    content: '\e9cb';
  }
  .icon-attachment:before {
    content: '\e9cd';
  }
  .icon-eye:before {
    content: '\e9ce';
  }
  .icon-plus:before {
    content: '\ea0a';
  }
  .icon-minus:before {
    content: '\ea0b';
  }
  .icon-cross:before {
    content: '\ea0f';
  }
  .icon-checkmark:before {
    content: '\ea10';
  }
  .icon-arrow-right2:before {
    content: '\ea3c';
  }
  .icon-arrow-left2:before {
    content: '\ea40';
  }
  .icon-circle-up:before {
    content: '\ea41';
  }
  .icon-circle-right:before {
    content: '\ea42';
  }
  .icon-circle-down:before {
    content: '\ea43';
  }
  .icon-circle-left:before {
    content: '\ea44';
  }
  .icon-google-plus2:before {
    content: '\ea8c';
  }
  .icon-facebook2:before {
    content: '\ea91';
  }
  .icon-instagram:before {
    content: '\ea92';
  }
  .icon-twitter:before {
    content: '\ea96';
  }
  .icon-dropbox:before {
    content: '\eaae';
  }
  .icon-tumblr2:before {
    content: '\eaba';
  }
  .icon-skype:before {
    content: '\eac5';
  }
  .icon-linkedin:before {
    content: '\eac9';
  }
  .icon-pinterest:before {
    content: '\ead1';
  }
