// QQ Vars
$qq-font-color: #000;
$control-bg: #fff;
$inputHeightLarge: 4.1rem;

.quickQuote {
  text-align: center;
  font-weight: 500;
  margin-top: -4rem;
  z-index: auto;
  position: relative;

  @include respond-to(large) {
    margin-top: -8rem;
  }

  .type {
    margin: 0 auto;
    width: 100%;
    max-width: 96rem;

    button {
      width: 45%;
      height: 4.1rem;
      margin: 0 0.5rem;
      background: rgba(255, 255, 255, 0.8);
      color: rgba(0, 0, 0, 0.4);
      border: none;
      text-align: center;
      outline: none;
      cursor: pointer;
      img {
        width: 22px;
        vertical-align: middle;
        margin: 0 0.5rem;
        opacity: 0.4;
      }
      &.selected {
        background: white;
        color: $qq-font-color;
        img {
          opacity: 1;
        }
      }
    }

    @include respond-to(large) {
      button {
        width: 22rem;
      }
    }
  }
  .criteria {
    background: white;
    padding: 2rem 0.5rem;
    max-width: 96rem;
    margin: 0 auto;
    box-shadow: 1px 7px 20px 4px rgba(0, 0, 0, 0.15);
    padding-left: 20px;
    padding-right: 20px;

    label {
      display: block;
      color: color(blue, lighter-1);
      text-align: left;
      text-transform: uppercase;
      font-weight: 600;
    }
    .form-group {
      @include respond-to(large) {
        &.travellers-group {
          width: 8.5rem;
        }
      }
      .Select-value-label {
        line-height: 38px;
      }
      margin: 2rem 0;
      div {
        background: #eaeaea;
        border: none;
        &.is-open {
          z-index: 2;
        }
      }
      input {
        height: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #eaeaea;
        border: none;
      }
    }
    .Select-control {
      outline: none;
      background: $control-bg;
      border: 1px solid #cacccd;
      border-radius: 0;
    }
    .qqSubmitBtn {
      background: color(blue, lighter-1);
      display: inline-block;
      width: 100%;
      margin: 2rem 0;
      height: auto;
      text-transform: uppercase;
    }
    .qqSubmitBtn:hover {
      background: #109fcd;
    }
    .DateRangePicker {
      display: block;
      .DateRangePickerInput {
        display: block;
      }
    }
    .DateInput {
      padding: 8px 8px;
      cursor: pointer;
    }
    @include respond-to(large) {
      .form-group {
        width: 18%;
        display: inline-block;
        vertical-align: bottom;
        margin: 2rem 0.5rem;
        &.date-group {
          width: auto;
        }
      }
      .xl-size {
        width: 25%;
      }
      .sm-size {
        width: 8%;
      }
      .singleDatePicker {
        input {
          text-align: left;
        }
      }
      .Select-control {
        height: 3.9rem;
        text-align: left;
      }
      .DateRangePicker {
        display: inline-block;
        .DateRangePickerInput {
          display: inline-block;
        }
      }
      .qqSubmitBtn {
        width: 12%;
        height: 4.1rem;
        margin: 2rem 0.5rem;
      }
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="password"] {
    height: 33px;
    width: 100%;
    background: $control-bg;
    border: 1px solid $control-bg;
    text-align: center;
    cursor: pointer;

    @include respond-to(large) {
      height: $inputHeightLarge;
      text-align: left;
      padding: 1rem;
    }
  }

  .errMsg {
    background: color(red);
    color: white;
    padding: 1rem 0;
  }
}
