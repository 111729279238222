body {
  margin: 0;
  font: 400 1.5rem/2rem Roboto,Helvetica,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.badge-secondary {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  background-color: #01BEEF;
  color: #ffffff;
  font-size: 0.75em;
  font-weight: bold;
  margin-left: 10px;
}