/*
// Base Stylings
// ------------------------------------------------ */

/*==== General ====*/
html {
  font-size: 10px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // @include respond-to(medium) {
  //   font-size: $font-size;
  // }
  // @include respond-to(large) {
  //   font-size: $font-size;
  // }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font: $font-weight #{$font-size}/#{$line-height} $font-base;
  color: $font-color;
  background: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
a {
  color: $link-color;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}
p a {
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    top: 90%;
    left: 0;
    width: 100%;
    height: 0.14em;
    background: #636363;
    opacity: 0.3;
    content: "";
    border-radius: 6px;
    transform: scale(0.7);
    transition: all 0.4s ease-in-out;
  }
  &:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s ease-in-out;
  }
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#main {
  margin-top: -10px;
  background: color(grey, lighter-2);
  @include respond-to(large) {
    margin-top: 0px;
  }
}
