/*
// Font Families
// ------------------------------------------------ */

// Font Icons ===================================
@font-face {
    font-family: $font-icons-name;
    src: url('../../fonts/#{$font-icons-name}.ttf?#{$font-cache}') format('truetype'),
         url('../../fonts/#{$font-icons-name}.woff?#{$font-cache}') format('woff'),
         url('../../fonts/#{$font-icons-name}.svg?#{$font-cache}#qcr') format('svg');
    font-weight: normal;
    font-style: normal;
}
