.traveller {
  margin: 2.5rem 0;
  h5 {
    color: #787878;
    font-size: 2.3rem;
  }

  .contactInfo {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
  }

  .form-group {
    display: block;
    margin: 2rem 0;
    label {
      display: block;
      color: color(blue, lighter-1);
      font-weight: 600;
      font-size: 1.3rem;
      text-transform: uppercase;
      sup { color: red; }
    }
    input[type=text],
    input[type=email] {
      width: 100%;
      height: 3rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0 1rem;
    }

    .rdatez {
      input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 1rem;
      }
    }

    .DateInput {
      background: white;
      padding: 3px;
      cursor: pointer;
      input[type=text] { cursor: pointer; }
    }
    .SingleDatePickerInput__calendar-icon { padding: 5px; }

    @include respond-to(large) {
      width: 48%;
      margin: 1rem 0;
      display: inline-block;
      vertical-align: top;
      &:nth-child(odd) {
        float: right;
      }
    }
  }

  .rate-info {
    border: 2px solid #a7a7a7;
    display: inline-block;
    text-transform: uppercase;
    padding: .2rem .5rem;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    border-radius: 3px;
    margin-right: 10px;
  }
}
