// SweetAlert2
// github.com/sweetalert2/sweetalert2

$swal2-white:       #fff !default;
$swal2-black:       #000 !default;

// BOX MODEL
$swal2-width: 32em !default;
$swal2-padding: 1.25em !default;
$swal2-border-radius: .3125em !default;
$swal2-box-shadow: #d9d9d9 !default;

// BACKGROUND
$swal2-background: $swal2-white !default;

// TYPOGRAPHY
$swal2-font: inherit !default;
$swal2-font-size: 1rem !default;

// BACKDROP
$swal2-backdrop: rgba($swal2-black, .4) !default;

// ICONS
$swal2-icon-size: 5em !default;
$swal2-icon-margin: 1.25em auto 1.875em !default;
$swal2-icon-zoom: normal !default;
$swal2-success: #a5dc86 !default;
$swal2-success-border: rgba($swal2-success, .3) !default;
$swal2-error: #f27474 !default;
$swal2-warning: #f8bb86 !default;
$swal2-info: #3fc3ee !default;
$swal2-question: #87adbd !default;

// IMAGE
$swal2-image-margin: 1.25em auto !default;

// TITLE
$swal2-title-margin: 0 0 .4em !default;
$swal2-title-color: lighten($swal2-black, 35) !default;
$swal2-title-font-size: 2.875rem !default;

// CONTENT
$swal2-content-color: lighten($swal2-black, 33) !default;
$swal2-content-font-size: 1.125em !default;

// INPUT
$swal2-input-margin: 1em auto !default;
$swal2-input-height: 2.625em !default;
$swal2-input-padding: .75em !default;
$swal2-input-border: lighten($swal2-black, 85) !default;
$swal2-input-border-radius: .1875em !default;
$swal2-input-border-focus: #b4dbed !default;
$swal2-input-box-shadow: rgba($swal2-black, .06) !default;
$swal2-input-box-shadow-focus: #c4e6f5 !default;
$swal2-input-font-size: 1.125em !default;

// TEXTAREA SPECIFIC VARIABLES
$swal2-textarea-height: 6.75em !default;

// VALIDATION ERROR
$swal2-validationerror-justify-content: center !default;
$swal2-validationerror-padding: .625em !default;
$swal2-validationerror-background: lighten($swal2-black, 94) !default;
$swal2-validationerror-color: lighten($swal2-black, 40) !default;
$swal2-validationerror-font-size: 1em !default;
$swal2-validationerror-font-weight: 300 !default;
$swal2-validationerror-icon-background: $swal2-error !default;
$swal2-validationerror-icon-color: $swal2-white !default;
$swal2-validationerror-icon-zoom: normal !default;

// PROGRESS STEPS
$swal2-progress-steps-margin: 0 0 1.25em !default;
$swal2-progress-steps-distance: 2.5em !default;

// FOOTER
$swal2-footer-margin: 1.25em 0 0 !default;
$swal2-footer-border-color: #eee !default;
$swal2-footer-color: lighten($swal2-black, 33) !default;
$swal2-footer-font-size: 1em !default;

// ANIMATIONS
$swal2-show-animation: swal2-show .3s !default;
$swal2-hide-animation: swal2-hide .15s forwards !default;
$swal2-success-line-tip-animation: swal2-animate-success-line-tip .75s !default;
$swal2-success-line-long-animation: swal2-animate-success-line-long .75s !default;
$swal2-success-circular-line-animation: swal2-rotate-success-circular-line 4.25s ease-in !default;
$swal2-error-icon-animation: swal2-animate-error-icon .5s !default;
$swal2-error-x-mark-animation: swal2-animate-error-x-mark .5s !default;

// CLOSE BUTTON
$swal2-close-button-position: absolute !default;
$swal2-close-button-gap: 0 !default;
$swal2-close-button-transition: color .1s ease-out !default;
$swal2-close-button-border: none !default;
$swal2-close-button-border-radius: 0 !default;
$swal2-close-button-background: transparent !default;
$swal2-close-button-color: lighten($swal2-black, 80) !default;
$swal2-close-button-font-size: 2.5em !default;

// CLOSE BUTTON:HOVER
$swal2-close-button-hover-transform: none !default;
$swal2-close-button-hover-color: $swal2-error !default;

// ACTIONS
$swal2-actions-margin: 1.25em auto 0 !default;

// CONFIRM BUTTON
$swal2-confirm-button-border: 0 !default;
$swal2-confirm-button-border-radius: .25em !default;
$swal2-confirm-button-background-color: #3085d6 !default;
$swal2-confirm-button-color: $swal2-white !default;
$swal2-confirm-button-font-size: 1.0625em !default;

// CANCEL BUTTON
$swal2-cancel-button-border: 0 !default;
$swal2-cancel-button-border-radius: .25em !default;
$swal2-cancel-button-background-color: #aaa !default;
$swal2-cancel-button-color: $swal2-white !default;
$swal2-cancel-button-font-size: 1.0625em !default;

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-darken-hover: rgba($swal2-black, .1) !default;
$swal2-button-darken-active: rgba($swal2-black, .2) !default;
$swal2-button-focus-outline: rgba(50, 100, 150, .4) !default;

// TOASTS
$swal2-toast-width: auto !default;
$swal2-toast-padding: .625em !default;
$swal2-toast-title-font-size: 1em !default;
$swal2-toast-content-font-size: 1em !default;
$swal2-toast-input-font-size: 1em !default;
$swal2-toast-validation-font-size: 1em !default;
$swal2-toast-buttons-font-size: 1em !default;

// Appearance animation
@keyframes swal2-show {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

// Disppearance animation
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(.5);
    opacity: 0;
  }
}

// Success icon animations
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0;
  }

  54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  }

  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  }

  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }

  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }

  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

// Error icon animations
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(.4);
    opacity: 0;
  }

  50% {
    margin-top: 1.625em;
    transform: scale(.4);
    opacity: 0;
  }

  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// https://stackoverflow.com/a/30250161/1331425
@mixin not($ignor-list...) {
  @if (length($ignor-list) == 1) {
    $ignor-list: nth($ignor-list, 1);
  }

  $not-output: '';
  @each $not in $ignor-list {
    $not-output: $not-output + ':not(#{$not})';
  }

  &#{$not-output} {
    @content;
  }
}

body {
  &.swal2-toast-shown {
    &.swal2-has-input > .swal2-container {
      & > .swal2-toast {
        flex-direction: column;
        align-items: stretch;

        .swal2-actions {
          flex: 1;
          align-self: stretch;
          justify-content: flex-end;
          height: 2.2em;
        }

        .swal2-loading {
          justify-content: center;
        }

        .swal2-input {
          height: 2em;
          margin: .3125em auto;
          font-size: $swal2-toast-input-font-size;
        }

        .swal2-validationerror {
          font-size: $swal2-toast-validation-font-size;
        }
      }
    }

    & > .swal2-container {
      position: fixed;
      background-color: transparent;

      &.swal2-shown {
        background-color: transparent;
      }

      &.swal2-top {
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-top-end,
      &.swal2-top-right {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
      }

      &.swal2-top-start,
      &.swal2-top-left {
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
      }

      &.swal2-center-start,
      &.swal2-center-left {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        transform: translateY(-50%);
      }

      &.swal2-center {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.swal2-center-end,
      &.swal2-center-right {
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
      }

      &.swal2-bottom-start,
      &.swal2-bottom-left {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
      }

      &.swal2-bottom {
        top: auto;
        right: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-bottom-end,
      &.swal2-bottom-right {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
      }
    }
  }
}

.swal2-popup {
  &.swal2-toast {
    flex-direction: row;
    align-items: center;
    width: $swal2-toast-width;
    padding: $swal2-toast-padding;
    box-shadow: 0 0 .625em $swal2-box-shadow;
    overflow-y: hidden;

    .swal2-header {
      flex-direction: row;
    }

    .swal2-title {
      justify-content: flex-start;
      margin: 0 .6em;
      font-size: $swal2-toast-title-font-size;
    }

    .swal2-close {
      position: initial;
    }

    .swal2-content {
      justify-content: flex-start;
      font-size: $swal2-toast-content-font-size;
    }

    .swal2-icon {
      width: 2em;
      min-width: 2em;
      height: 2em;
      margin: 0;

      &-text {
        font-size: 2em;
        font-weight: bold;
        line-height: 1em;
      }

      &.swal2-success {

        .swal2-success-ring {
          width: 2em;
          height: 2em;
        }
      }

      &.swal2-error {

        [class^='swal2-x-mark-line'] {
          top: .875em;
          width: 1.375em;

          &[class$='left'] {
            left: .3125em;
          }

          &[class$='right'] {
            right: .3125em;
          }
        }
      }
    }

    .swal2-actions {
      height: auto;
      margin: 0 .3125em;
    }

    .swal2-styled {
      margin: 0 .3125em;
      padding: .3125em .625em;
      font-size: $swal2-toast-buttons-font-size;

      &:focus {
        box-shadow: 0 0 0 .0625em $swal2-white, 0 0 0 .125em $swal2-button-focus-outline;
      }
    }

    .swal2-success {
      border-color: $swal2-success;

      [class^='swal2-success-circular-line'] { // Emulate moving circular line
        position: absolute;
        width: 2em;
        height: 2.8125em;
        transform: rotate(45deg);
        border-radius: 50%;

        &[class$='left'] {
          top: -.25em;
          left: -.9375em;
          transform: rotate(-45deg);
          transform-origin: 2em 2em;
          border-radius: 4em 0 0 4em;
        }

        &[class$='right'] {
          top: -.25em;
          left: .9375em;
          transform-origin: 0 2em;
          border-radius: 0 4em 4em 0;
        }
      }

      .swal2-success-ring {
        width: 2em;
        height: 2em;
      }

      .swal2-success-fix {
        top: 0;
        left: .4375em;
        width: .4375em;
        height: 2.6875em;
      }

      [class^='swal2-success-line'] {
        height: .3125em;

        &[class$='tip'] {
          top: 1.125em;
          left: .1875em;
          width: .75em;
        }

        &[class$='long'] {
          top: .9375em;
          right: .1875em;
          width: 1.375em;
        }
      }
    }

    &.swal2-show {
      animation: showSweetToast .5s;
    }

    &.swal2-hide {
      animation: hideSweetToast .2s forwards;
    }

    .swal2-animate-success-icon {
      .swal2-success-line-tip {
        animation: animate-toast-success-tip .75s;
      }

      .swal2-success-line-long {
        animation: animate-toast-success-long .75s;
      }
    }
  }
}

// Animations
@keyframes showSweetToast {
  0% {
    transform: translateY(-.625em) rotateZ(2deg);
    opacity: 0;
  }

  33% {
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5;
  }

  66% {
    transform: translateY(.3125em) rotateZ(2deg);
    opacity: .7;
  }

  100% {
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}

@keyframes hideSweetToast {
  0% {
    opacity: 1;
  }

  33% {
    opacity: .5;
  }

  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}

@keyframes animate-toast-success-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0;
  }

  54% {
    top: .125em;
    left: .125em;
    width: 0;
  }

  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em;
  }

  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em;
  }

  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em;
  }
}

@keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }

  65% {
    top: 1.25em;
    right: .9375em;
    width: 0;
  }

  84% {
    top: .9375em;
    right: 0;
    width: 1.125em;
  }

  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em;
  }
}

html,
body {
  &.swal2-shown {
    @include not(
      '.swal2-no-backdrop',
      '.swal2-toast-shown'
    ) {
      height: auto; // #781
      overflow-y: hidden;
    }
  }
}

body {
  &.swal2-no-backdrop {

    .swal2-shown {
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      background-color: transparent;

      & > .swal2-modal {
        box-shadow: 0 0 10px $swal2-backdrop;
      }

      &.swal2-top {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-top-start,
      &.swal2-top-left {
        top: 0;
        left: 0;
      }

      &.swal2-top-end,
      &.swal2-top-right {
        top: 0;
        right: 0;
      }

      &.swal2-center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.swal2-center-start,
      &.swal2-center-left {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &.swal2-center-end,
      &.swal2-center-right {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &.swal2-bottom {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-bottom-start,
      &.swal2-bottom-left {
        bottom: 0;
        left: 0;
      }

      &.swal2-bottom-end,
      &.swal2-bottom-right {
        right: 0;
        bottom: 0;
      }
    }
  }
}

.swal2-container {
  // centering
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;

  // backdrop
  background-color: transparent;

  z-index: 1060;
  overflow-x: hidden;

  // sweetalert2/issues/905
  -webkit-overflow-scrolling: touch;

  &.swal2-top {
    align-items: flex-start;
  }

  &.swal2-top-start,
  &.swal2-top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.swal2-top-end,
  &.swal2-top-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.swal2-center {
    align-items: center;
  }

  &.swal2-center-start,
  &.swal2-center-left {
    align-items: center;
    justify-content: flex-start;
  }

  &.swal2-center-end,
  &.swal2-center-right {
    align-items: center;
    justify-content: flex-end;
  }

  &.swal2-bottom {
    align-items: flex-end;
  }

  &.swal2-bottom-start,
  &.swal2-bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.swal2-bottom-end,
  &.swal2-bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &.swal2-grow-fullscreen > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-self: stretch;
    justify-content: center;
  }

  &.swal2-grow-row > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-content: center;
    justify-content: center;
  }

  &.swal2-grow-column {
    flex: 1;
    flex-direction: column;

    &.swal2-top,
    &.swal2-center,
    &.swal2-bottom {
      align-items: center;
    }

    &.swal2-top-start,
    &.swal2-center-start,
    &.swal2-bottom-start,
    &.swal2-top-left,
    &.swal2-center-left,
    &.swal2-bottom-left {
      align-items: flex-start;
    }

    &.swal2-top-end,
    &.swal2-center-end,
    &.swal2-bottom-end,
    &.swal2-top-right,
    &.swal2-center-right,
    &.swal2-bottom-right {
      align-items: flex-end;
    }

    & > .swal2-modal {
      display: flex !important;
      flex: 1;
      align-content: center;
      justify-content: center;
    }
  }

  @include not(
    '.swal2-top',
    '.swal2-top-start',
    '.swal2-top-end',
    '.swal2-top-left',
    '.swal2-top-right',
    '.swal2-center-start',
    '.swal2-center-end',
    '.swal2-center-left',
    '.swal2-center-right',
    '.swal2-bottom',
    '.swal2-bottom-start',
    '.swal2-bottom-end',
    '.swal2-bottom-left',
    '.swal2-bottom-right') {
    & > .swal2-modal {
      margin: auto;
    }
  }

  @include ie {
    .swal2-modal {
      margin: 0 !important;
    }
  }

  &.swal2-fade {
    transition: background-color .1s;
  }

  &.swal2-shown {
    background-color: $swal2-backdrop;
  }
}

.swal2-popup {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: $swal2-width;
  max-width: 100%;
  padding: $swal2-padding;
  border-radius: $swal2-border-radius;
  background: $swal2-background;
  font-family: $swal2-font;
  font-size: $swal2-font-size;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &.swal2-loading {
    overflow-y: hidden;
  }

  .swal2-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .swal2-title {
    display: block;
    position: relative;
    max-width: 100%;
    margin: $swal2-title-margin;
    padding: 0;
    color: $swal2-title-color;
    font-size: $swal2-title-font-size;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
  }

  .swal2-actions {
    align-items: center;
    justify-content: center;
    margin: $swal2-actions-margin;

    &:not(.swal2-loading) {
      .swal2-styled {
        &[disabled] {
          opacity: .4;
        }

        &:hover {
          background-image: linear-gradient($swal2-button-darken-hover, $swal2-button-darken-hover);
        }

        &:active {
          background-image: linear-gradient($swal2-button-darken-active, $swal2-button-darken-active);
        }
      }
    }

    &.swal2-loading {
      .swal2-styled {
        &.swal2-confirm {
          width: 2.5em;
          height: 2.5em;
          margin: .46875em;
          padding: 0;
          border: .25em solid transparent;
          border-radius: 100%;
          border-color: transparent;
          background-color: transparent !important;
          color: transparent;
          cursor: default;
          box-sizing: border-box;
          animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
          user-select: none;
        }

        &.swal2-cancel {
          margin-right: 30px;
          margin-left: 30px;
        }
      }

      :not(.swal2-styled) {
        &.swal2-confirm {
          &:after {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            border: 3px solid lighten($swal2-black, 60);
            border-radius: 50%;
            border-right-color: transparent;
            box-shadow: 1px 1px 1px $swal2-white;
            content: '';
            animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
          }
        }
      }
    }
  }

  .swal2-styled {
    margin: 0 .3125em;
    padding: .625em 2em;
    font-weight: 500;
    box-shadow: none;

    &:not([disabled]) {
      cursor: pointer;
    }

    &.swal2-confirm {
      border: $swal2-confirm-button-border;
      border-radius: $swal2-confirm-button-border-radius;
      background: initial;
      background-color: $swal2-confirm-button-background-color;
      color: $swal2-confirm-button-color;
      font-size: $swal2-confirm-button-font-size;
    }

    &.swal2-cancel {
      border: $swal2-cancel-button-border;
      border-radius: $swal2-cancel-button-border-radius;
      background: initial;
      background-color: $swal2-cancel-button-background-color;
      color: $swal2-cancel-button-color;
      font-size: $swal2-cancel-button-font-size;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $swal2-white, 0 0 0 4px $swal2-button-focus-outline;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  .swal2-footer {
    justify-content: center;
    margin: $swal2-footer-margin;
    padding-top: 1em;
    border-top: 1px solid $swal2-footer-border-color;
    color: $swal2-footer-color;
    font-size: $swal2-footer-font-size;
  }

  .swal2-image {
    max-width: 100%;
    margin: $swal2-image-margin;
  }

  .swal2-close {
    position: $swal2-close-button-position;
    top: $swal2-close-button-gap;
    right: $swal2-close-button-gap;
    justify-content: center;
    width: 1.2em;
    min-width: 1.2em;
    height: 1.2em;
    margin: 0;
    padding: 0;
    transition: $swal2-close-button-transition;
    border: $swal2-close-button-border;
    border-radius: $swal2-close-button-border-radius;
    background: $swal2-close-button-background;
    color: $swal2-close-button-color;
    font-family: serif;
    // calc is needed for the correct focus outline, w/o calc: https://goo.gl/T3NYRm
    font-size: calc(#{$swal2-close-button-font-size} - #{$swal2-close-button-font-size*.1});
    line-height: 1.2em;
    cursor: pointer;

    &:hover {
      transform: $swal2-close-button-hover-transform;
      color: $swal2-close-button-hover-color;
    }
  }

  > .swal2-input,
  > .swal2-file,
  > .swal2-textarea,
  > .swal2-select,
  > .swal2-radio,
  > .swal2-checkbox {
    display: none;
  }

  .swal2-content {
    justify-content: center;
    margin: 0;
    padding: 0;
    color: $swal2-content-color;
    font-size: $swal2-content-font-size;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
  }

  #swal2-content {
    text-align: center;
  }

  .swal2-input,
  .swal2-file,
  .swal2-textarea,
  .swal2-select,
  .swal2-radio,
  .swal2-checkbox {
    margin: $swal2-input-margin;
  }

  .swal2-input,
  .swal2-file,
  .swal2-textarea {
    width: 100%;
    transition: border-color .3s, box-shadow .3s;
    border: 1px solid $swal2-input-border;
    border-radius: $swal2-input-border-radius;
    font-size: $swal2-input-font-size;
    box-shadow: inset 0 1px 1px $swal2-input-box-shadow;
    box-sizing: border-box;

    &.swal2-inputerror {
      border-color: $swal2-error !important;
      box-shadow: 0 0 2px $swal2-error !important;
    }

    &:focus {
      border: 1px solid $swal2-input-border-focus;
      outline: none;
      box-shadow: 0 0 3px $swal2-input-box-shadow-focus;
    }

    &::placeholder {
      color: lighten($swal2-black, 80);
    }
  }

  .swal2-range {
    input {
      width: 80%;
    }

    output {
      width: 20%;
      font-weight: 600;
      text-align: center;
    }

    input,
    output {
      height: $swal2-input-height;
      margin: $swal2-input-margin;
      padding: 0;
      font-size: $swal2-input-font-size;
      line-height: $swal2-input-height;
    }
  }

  .swal2-input {
    height: $swal2-input-height;
    padding: $swal2-input-padding;

    &[type='number'] {
      max-width: 10em;
    }
  }

  .swal2-file {
    font-size: $swal2-input-font-size;
  }

  .swal2-textarea {
    height: $swal2-textarea-height;
    padding: $swal2-input-padding;
  }

  .swal2-select {
    min-width: 50%;
    max-width: 100%;
    padding: .375em .625em;
    color: lighten($swal2-black, 33);
    font-size: $swal2-input-font-size;
  }

  .swal2-radio,
  .swal2-checkbox {
    align-items: center;
    justify-content: center;

    label {
      margin: 0 .6em;
      font-size: $swal2-input-font-size;
    }

    input {
      margin: 0 .4em;
    }
  }

  .swal2-validationerror {
    display: none;
    align-items: center;
    justify-content: $swal2-validationerror-justify-content;
    padding: $swal2-validationerror-padding;
    background: $swal2-validationerror-background;
    color: $swal2-validationerror-color;
    font-size: $swal2-validationerror-font-size;
    font-weight: $swal2-validationerror-font-weight;
    overflow: hidden;

    &:before {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      margin: 0 .625em;
      border-radius: 50%;
      background-color: $swal2-validationerror-icon-background;
      color: $swal2-validationerror-icon-color;
      font-weight: 600;
      line-height: 1.5em;
      text-align: center;
      content: '!';
      zoom: $swal2-validationerror-icon-zoom;
    }
  }
}

@supports (-ms-accelerator: true) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

.swal2-icon {
  position: relative;
  justify-content: center;
  width: $swal2-icon-size;
  height: $swal2-icon-size;
  margin: $swal2-icon-margin;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: $swal2-icon-size;
  cursor: default;
  box-sizing: content-box;
  user-select: none;
  zoom: $swal2-icon-zoom;

  &-text {
    font-size: 3.75em;
  }

  &.swal2-error {
    border-color: $swal2-error;

    .swal2-x-mark {
      position: relative;
      flex-grow: 1;
    }

    [class^='swal2-x-mark-line'] {
      display: block;
      position: absolute;
      top: 2.3125em;
      width: 2.9375em;
      height: .3125em;
      border-radius: .125em;
      background-color: $swal2-error;

      &[class$='left'] {
        left: 1.0625em;
        transform: rotate(45deg);
      }

      &[class$='right'] {
        right: 1em;
        transform: rotate(-45deg);
      }
    }
  }

  &.swal2-warning {
    border-color: lighten($swal2-warning, 7);
    color: $swal2-warning;
  }

  &.swal2-info {
    border-color: lighten($swal2-info, 20);
    color: $swal2-info;
  }

  &.swal2-question {
    border-color: lighten($swal2-question, 20);
    color: $swal2-question;
  }

  &.swal2-success {
    border-color: $swal2-success;

    [class^='swal2-success-circular-line'] { // Emulate moving circular line
      position: absolute;
      width: 3.75em;
      height: 7.5em;
      transform: rotate(45deg);
      border-radius: 50%;

      &[class$='left'] {
        top: -.4375em;
        left: -2.0635em;
        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em;
      }

      &[class$='right'] {
        top: -.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0;
      }
    }

    .swal2-success-ring { // Ring
      position: absolute;
      top: -.25em;
      left: -.25em;
      width: 100%;
      height: 100%;
      border: .25em solid $swal2-success-border;
      border-radius: 50%;
      z-index: 2;
      box-sizing: content-box;
    }

    .swal2-success-fix { // Hide corners left from animation
      position: absolute;
      top: .5em;
      left: 1.625em;
      width: .4375em;
      height: 5.625em;
      transform: rotate(-45deg);
      z-index: 1;
    }

    [class^='swal2-success-line'] {
      display: block;
      position: absolute;
      height: .3125em;
      border-radius: .125em;
      background-color: $swal2-success;
      z-index: 2;

      &[class$='tip'] {
        top: 2.875em;
        left: .875em;
        width: 1.5625em;
        transform: rotate(45deg);
      }

      &[class$='long'] {
        top: 2.375em;
        right: .5em;
        width: 2.9375em;
        transform: rotate(-45deg);
      }
    }
  }
}

.swal2-progresssteps {
  $lightblue: #add8e6;
  $blue: #3085d6;

  align-items: center;
  margin: $swal2-progress-steps-margin;
  padding: 0;
  font-weight: 600;

  li {
    display: inline-block;
    position: relative;
  }

  .swal2-progresscircle {
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background: $blue;
    color: $swal2-white;
    line-height: 2em;
    text-align: center;
    z-index: 20;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.swal2-activeprogressstep {
      background: $blue;

      ~ .swal2-progresscircle {
        background: $lightblue;
      }

      ~ .swal2-progressline {
        background: $lightblue;
      }
    }
  }

  .swal2-progressline {
    width: $swal2-progress-steps-distance;
    height: .4em;
    margin: 0 -1px;
    background: $blue;
    z-index: 10;
  }
}

// github.com/sweetalert2/sweetalert2/issues/268
[class^='swal2'] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: $swal2-show-animation;

  &.swal2-noanimation {
    animation: none;
  }
}

.swal2-hide {
  animation: $swal2-hide-animation;

  &.swal2-noanimation {
    animation: none;
  }
}

// Right-to-left support
[dir='rtl'] {
  .swal2-close {
    right: auto;
    left: $swal2-close-button-gap;
  }
}

// Success icon animation
.swal2-animate-success-icon {
  .swal2-success-line-tip {
    animation: $swal2-success-line-tip-animation;
  }

  .swal2-success-line-long {
    animation: $swal2-success-line-long-animation;
  }

  .swal2-success-circular-line-right {
    animation: $swal2-success-circular-line-animation;
  }
}

// Error icon animation
.swal2-animate-error-icon {
  animation: $swal2-error-icon-animation;

  .swal2-x-mark {
    animation: $swal2-error-x-mark-animation;
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
