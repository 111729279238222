.vw-home {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include respond-to(large) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: color(blue, lighter-1);
    margin: .5rem 0;
  }
  .intro {
    text-align: justify;
    .ib { width: 100%; }
    h2 {
      color: color(blue, lighter-1);
      font-size: 2.3rem;
      font-weight: 400;
    }

    @include respond-to(large) {
      text-align: left;
      .ib {
        width: 58%;
        &:last-child {
          width: 40%;
          float: right;
        }
      }
      h2 { font-size: 3rem; }
    }

    @include respond-to(xlarge) {
      .ib:last-child { width: 35%; }
    }
  }
  .destinations { padding: 5rem 0; }
  .destination {
    width: 45%;
    display: inline-block;
    margin: .5rem;
    img { border-bottom: 10px solid color(blue, lighter-1); }
    h4 {
      color: #373737;
      font-size: 2rem;
      font-weight: 400;
    }
    a { color: #282828; }
    @include respond-to(large) {
      width: 35%;
      margin: 1.5rem;
    }

    @include respond-to(xlarge) {
      width: 22%;
      margin: 2rem .5rem;
    }
  }
  .home-video {
    position: relative;
    padding: 3rem 0;
    .blue {
      background: color(blue, darker-1);
      position: relative;
      padding: 1rem 0;
      top: 0;
      img {
        width: 30%;
        display: inline-block;
        vertical-align: middle;
      }
      h2 {
        color: white;
        font-size: 2rem;
        line-height: 1.2;
        vertical-align: middle;
      }
      span { color: color(blue, lighter-1); }
    }

    .cover {
      z-index: 30;
      position: relative;
    }

    @include respond-to(large) {
      .blue {
        width: 100%;
        position: absolute;
        padding: 3rem 0;
        height: 33rem;
        img {
          display: inline-block;
          width: 20%;
          margin: 0 2rem;
        }
        h2 {
          display: inline-block;
          font-size: 2.8rem;
        }
      }

      .cover {
        width: 75%;
        margin: 10rem auto 0;
        padding: 4rem 6rem;
        background: rgba(255, 255, 255, .2);
      }
    }
  }

  .about {
    padding: 2rem 0;
    text-align: justify;
    h2 {
      color: color(blue, lighter-1);
      font-size: 2.3rem;
      font-weight: 400;
      line-height: 1.5;
    }

    @include respond-to(large) {
      p {
        display: inline-block;
        width: 48%;
        vertical-align: top;
      }
    }
  }

  .partners {
    background: white;
    padding: 2rem 0 0;
    margin: 5rem 0;
    box-shadow: 1px 7px 20px 4px rgba(0, 0, 0, .15);
    h2 { font-size: 2rem; }
    img {
      width: 45%;
      display: inline-block;
      vertical-align: middle;
    }

    @include respond-to(large) {
      padding: 0;
      h2 {
        font-size: 2.5rem;
        display: inline-block;
      }
      img { width: 30%; }
      .divider {
        display: inline-block;
        width: 1px;
        height: 10rem;
        vertical-align: middle;
        margin: 0 2rem;
      }
    }
  }
}
