input:focus { 
  outline: 1px solid #2684ff;
  border: 1px solid #2684ff;
}
textarea:focus { 
  outline: 1px solid #2684ff;
  border: 1px solid #2684ff;
}

.rdtDay {
  cursor: pointer;
}

.rdtDay.available-day {
  background-color: #d4edda !important;
  color: #155724 !important;
}

.rdtDay.rdtDisabled {
  background-color: #f8f9fa !important;
  color: #999999 !important;
  cursor: not-allowed;
}

.rdtDay:hover:not(.rdtDisabled) {
  background-color: #28a745 !important;
  color: white !important;
}

