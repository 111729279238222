/*
// Site's Footer
// ------------------------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap");
$dividerColor: #0397cc;

//==== Base ====//
.main-footer {
  color: red;
  padding: 0.5rem;
  background: color(blue, "darker-1");
  color: white;
  padding: 3rem 0;

  a {
    color: white;
    display: block;
    line-height: 2;
    &:after {
      background: none;
    }
    &.number {
      display: inline-block;
      padding: 0 1rem;
    }
    &:nth-child(2) {
      border-right: 1px solid white;
    }
    &:hover {
      color: $link-hover-color;
    }
  }
  img.footer-logo {
    width: 11.5rem;
    margin: 2rem auto;
  }
  ul {
    text-align: center;
    text-transform: uppercase;
    li {
      display: block;
      a {
        padding: 1rem;
      }
    }
  }
  .divider {
    width: 90%;
    max-width: 980px;
    height: 1px;
    margin: 1.5rem auto;
    background: $dividerColor;
  }
  @include respond-to(large) {
    a {
      display: inline-block;
    }
    img.footer-logo {
      width: 22rem;
    }
    .social {
      display: inline-block;
    }
    ul li {
      display: inline-block;
    }
  }
}

/*FOOTER STYLES */
/* */
/* */

.footertop {
  background: url("https://www.roatanferry.com/footer_yellow.svg") 0 0 no-repeat
    padding-box;
  background-size: 100vw;
  background-color: #f4f4f4;
  padding-top: 30px;
  height: 400px;
  width: 100%;
  margin-bottom: 50px;
}

.footer {
  font-family: monospace !important;
  position: relative;
  background: transparent url("https://www.roatanferry.com/footer_blue.svg") 0%
    0% no-repeat padding-box;
  background-size: 100vw;
  height: auto;
  padding-top: 40px;
  margin-top: -440px;
}

.logofooter {
  position: absolute;
  top: 75px;
  left: 100px;
  width: 160px;
}
.footContent {
  text-align: center;
  padding-left: 115px;
  padding-right: 100px;
  padding-top: 100px;
  padding-bottom: 65px;
  margin-top: 40px;
  background-color: #004b88;
}

.footCol {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 14%;
  margin: 10px;
}
.footCol h1 {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
}
.footCol a {
  color: white;
  opacity: 0.7;
  font-weight: 300;
  font-size: 14px;
  font-family: "Open Sans";
}

.mobileFooter a {
  color: white;
  opacity: 0.8;
  font-weight: 300;
  font-size: 14px;
  font-family: "Open Sans";
}
.mobileFooter a:hover {
  color: white;
  opacity: 0.9;
  font-weight: 300;
  font-size: 14px;
  font-family: "Open Sans";
}

.footCol a:hover {
  color: #ccc;
  opacity: 0.6;
  font-weight: 300;
  font-size: 14px;
  font-family: "Open Sans";
}
.maillink {
  color: #9aeef9 !important;
  opacity: 0.9;
  margin-top: 10px;
}
.mailaFollow img {
  margin-left: 11px;
}

p.mailaFollow {
  color: #d0dbe7;
  font-family: "Open Sans";
}

.maillinkFollow {
  color: white;
  opacity: 0.7;
  margin-top: 10px;
}
.maillinkFollow a {
  opacity: 0.7;
}

.maillinkFollow a:hover {
  opacity: 1;
}

.maillinkFollow img {
  margin-top: 10px;
  cursor: pointer;
  margin-left: 10px;
  height: 23px;
  object-fit: cover;
}

.desktopfooter {
  display: block;
}
.mobileFooter {
  display: none;
}

@media only screen and (max-width: 850px) {
  .footertop {
    background-size: contain;
    height: 160px;
    width: 100%;
    margin-bottom: -133px;
  }
  .desktopfooter {
    display: none;
  }
  .mobileFooter {
    display: block;
  }

  .mobileFooter {
    background: #004b88;
    display: block;
    margin-top: 40px;
    padding-top: 50px;
    text-align: center;
  }
  .mobileFooter a {
    color: white;
  }

  .logofooter {
    margin-top: 20px;
    position: absolute;
    top: 35px;
    left: 50%;
    width: 120px;
    margin-left: -60px !important;
  }

  .footer {
    padding-top: 30px;
    margin-top: 0px;
  }
}

.suscribeForm {
  background-color: white;
}

/* FOOTER STYLES*/
