
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: $headings-margin 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

//
// Horizontal rules
//
hr {
  width: 100%;
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(color(grey, lighter-2), .6);
}
