.newSearchBar {
  padding: 2rem 1rem;
  background: #292c42;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  a { color: white; }
  b {
    width: 5rem;
    height: 5rem;
    display: inline-block;
    padding-top: 1rem;
    margin-right: 1rem;
    background: #1f2239;
    font-size: 3rem;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
  }
}
