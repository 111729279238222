.rdatez-btn.rdatez-btn-year {
  padding: 0 !important;
}
#livechat-compact-container {
  z-index: 10 !important;
}

.vw-guest {
  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    span {
      color: color(blue, lighter-1);
    }
  }
  .intro {
    display: block;
    @include respond-to(large) {
      display: flex;
    }
  }

  .pricesShown {
    font-size: 1.2rem;
    color: #a7a7a7;
    span {
      color: black;
    }
  }
  .result {
    margin: 3rem 0;
    width: 100%;
    display: flex;
    @include respond-to(large) {
      width: 48%;
      display: flex;
      vertical-align: top;
    }
    @include respond-to(xlarge) {
      width: 58%;
      .detail {
        display: block;
      }
      .route {
        width: 100%;
      }
    }
    // add flex only to one way
    .one-way {
      @include respond-to(xlarge) {
        display: flex !important;
      }
    }
  }
  .usefulInfo {
    margin: 3rem 0;
    width: 100%;
    padding: 2rem;
    background: white;
    p {
      font-size: 2rem;
      font-weight: 500;
    }
    ul {
      color: #8b8b8b;
      font-size: 1.4rem;
      font-weight: 500;
      span {
        color: color(blue, lighter-1);
        font-size: 2rem;
      }
    }

    @include respond-to(large) {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      margin-left: auto;
    }

    @include respond-to(xlarge) {
      width: 40%;
    }
  }
  hr {
    border-top: 1px solid #c1c1c1;
    margin: 2rem 0;
  }
  .no-padding {
    @include respond-to(large) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  .btnGuestContinue {
    width: 100%;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
    @include respond-to(large) {
      width: 20rem;
    }
  }
}
