.vw-search {
  .top-section {
    height: 6rem;
    background: #02457e;

    @include respond-to(large) {
      height: 10rem;
      border-bottom: 8px solid #edca48;
    }
  }
}
