.vw-tc {
	padding: 2rem;
	@include respond-to(large) {
		padding: 2rem 5rem;
	}
	.intro {
		width: 100%;
		margin: 0 auto;
		h4 {
			color: #22bcee;
		    font-size: 3rem;
		    font-weight: 400;
		}
		@include respond-to(large) {
			width: 70%;
		}
	}
}
