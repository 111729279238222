/*
// Site Header
// ------------------------------------------------ */

$mm-height: 100px;
$mm-heightLarge: 80px;
$mm-btnHeight: 50px;
$menuItems: 9;
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
$iconColor: #22bcee;

//==== Logo ====//
.main-logo {
  width: 9rem;
  padding: 0.557rem 0;
  margin: 0;

  a {
    height: 100%;
  }
  img {
    width: 95%;
    margin: 0 auto;
  }
  span {
    display: block;
    text-indent: -9999px;
  }
}

//==== Base ====//
.main-header {
  position: absolute;
  z-index: 900;
  top: 0;
  right: 0;
  left: 0;
  max-height: none;
  height: $mm-height;
  background: #02457e;
  transition: height 0.2s ease-out, background 1s linear;
  transition-delay: 0.2s;
  user-select: none;

  .item {
    position: absolute;
    top: 0;
    height: 50px;

    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(3) {
      right: 0;
    }
  }
  @include respond-to(large) {
    height: $mm-heightLarge;
  }
}
.mm-header {
  position: relative;
  z-index: z(after);
}
.mm-top {
  background: #0171ce;
  color: white;
  padding: 0.5rem 0;
  .social {
    display: none;
    i {
      margin: 0 0.5rem;
    }
  }
  .top-content {
    max-width: 77rem;
    margin: 0 auto;
  }
  p {
    color: white;
    display: block;
    margin: 0;
  }
  a {
    color: white;
    display: block;
    padding: 0 0.5rem;
    i {
      color: $iconColor;
    }
    &:after {
      background: none;
    }
    &.number {
      display: inline-block;
    }
    &:nth-child(2) {
      border-right: 1px solid $iconColor;
    }
  }
  @include respond-to(large) {
    p {
      display: inline-block;
      float: right;
    }
    a {
      display: inline-block;
    }
    .social {
      display: inline-block;
      float: left;
    }
  }
}
.main-menu {
  text-align: center;
}

//==== Menu List ====//
.mm-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: $mm-height 4rem 0;
  visibility: hidden;
  overflow: hidden;

  .mm-wrap {
    display: block;
  }
  .mm-item {
    display: block;
    width: 100%;
    color: rgba(255, 255, 255, 0.4);
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    opacity: 0;
    transform: scale(1.15) translateY(-30px);
    transition: transform 0.5s $cubic, opacity 0.6s $cubic,
      -webkit-transform 0.5s $cubic;
    font: 2rem/4.5rem "Helvetica", sans-serif;
    padding: 0 1.5rem;

    &.search {
      color: #fff;
      border-bottom-color: #fff;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
    @for $i from 1 through $menuItems {
      &:nth-child(#{$i}) {
        transition-delay: #{0.56 - ($i * 0.07)}s;
      }
    }
  }
  @include respond-to(xlarge) {
    position: relative;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    padding: 0;
    visibility: visible;

    .mm-item {
      position: relative;
      display: inline-block;
      width: auto;
      height: 50px;
      vertical-align: top;
      line-height: 50px;
      font-size: 1.6rem;
      color: #dcdcdc;
      border-bottom: none;
      opacity: 1;
      transform: none;
      transition-delay: 0s !important;

      &:hover {
        color: color(blue);
      }
    }
    .mm-wrap {
      position: relative;
      display: inline-block;
      min-width: 61rem;
    }
    .main-logo {
      width: 8rem;
      padding: 1rem 0;

      a {
        display: block;
        padding: 0;
      }
    }
  }
}

//==== Mobile menu ====//
.mm-btn {
  width: $mm-btnHeight;
  height: $mm-btnHeight;
  // transform: translate(-50%, -50%) rotate(0);
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  b {
    position: absolute;
    top: 0;
    left: 0;
    width: $mm-btnHeight;
    height: $mm-btnHeight;
    transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: rotate(0);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 40%;
      height: 2px;
      // background: color(blue);
      background: #fff;
      transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      content: "";
    }
    &:first-child {
      &:before {
        transition: transform 0.2s 0.2s;
        transform: translate(-50%, -8px);
      }
    }
    &:last-child {
      &:before {
        transition: transform 0.2s;
        transform: translate(-50%, 5px);
      }
    }
  }
}

//==== Cart ====//
#js-cart {
  position: relative;
  width: 5.2rem !important;
  height: 100%;
  margin-left: auto;
  text-align: center;
  transition: transform 0.25s 0.55s ease-out,
    -webkit-transform 0.25s 0.55s ease-out;

  & > div {
    height: 100%;
  }
  i {
    font-size: 3.5rem;
  }
}
.main-cart {
  width: 40px;
  height: 100%;
  min-height: $mm-height;
  color: #fff !important;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30px;
    transform: translate(-50%, -50%);
  }
  &:after {
    position: absolute;
    top: 0;
    height: 1px;
    width: 1px;
    padding: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(0 0 99.9% 99.9%);
    content: "";
  }
  &.full i:after {
    position: absolute;
    top: 9px;
    left: 15px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: multiColor 10s infinite ease-in-out;
    content: "";

    @include respond-to(xlarge) {
      top: 5px;
      left: 12px;
      background: #fff;
      animation: none;
    }
  }
  @include respond-to(xlarge) {
    // background: color(orange);
  }
}

//==== Mobile Menu Triggered ====//
.mm-trigger:checked ~ .main-header {
  height: 100%;
  // background: color(blue, darker-3);
  transition: height 0.3s ease-in, background 0.5s linear;
  transition-delay: 0.25s;

  .mm-btn {
    transform: rotate(90deg);

    b {
      &:first-child {
        transform: rotate(45deg);
      }
      &:last-child {
        transform: rotate(-45deg);
      }
      &:before {
        transform: translate(-50%, -1px);
      }
    }
  }
  .mm-list {
    visibility: visible;

    a {
      transform: scale(1) translateY(0);
      opacity: 1;
      @for $i from 1 through $menuItems {
        &:nth-child(#{$i}) {
          transition-delay: #{0.07 * $i + 0.2}s;
        }
      }
    }
  }
  #js-cart {
    transform: translateX(200%);
    transition: transform 0.55s 0.25s ease-out,
      -webkit-transform 0.55s 0.25s ease-out;
  }
}

.container-fluid-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 99;
}

.headerMain {
  z-index: 99999;
  position: relative;
  padding: 0px;
  height: 120px;
  width: 480px;
  background: transparent;
}

.stickyBar {
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  opacity: 1;
  padding: 0px;
  height: 123px;
  background: #02457e;
  transition: all 0.4s ease-in-out;
}

.logo {
  cursor: pointer;
  position: absolute;
  width: 180px;
  padding: 10px;
  left: 20%;
  top: 15px;
}

.sec {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 100px;
}

.BookBtnTop {
  position: absolute;
  z-index: 999;
  right: 100px;
  top: 33px;
  background: #0171ce;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  border-radius: 4px;
  width: 230px;
  text-align: center;
}

.miniMenu {
  position: absolute;
  z-index: 999;
  right: 350px;
  top: 50px;
}

.miniMenu a {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 18px;
  color: white;
  margin-left: 25px;
  transition: all 0.5s ease;
}

.miniMenu a:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 850px) {
  .stickyBar {
    display: none;
  }
  .container-fluid-header {
    position: relative;
    padding: 0;
    z-index: 9999;
    top: 0;
    width: 100%;
  }

  .headerMain {
    z-index: 99999;
    position: relative;
    margin-bottom: 0px;
    padding: 5px;
    height: 80px;
    width: 100% !important;
    background: #004b89;
  }

  .logo {
    position: absolute;
    width: 130px;
    padding: 10px;
    left: 10px;
    top: 10px;
  }

  .BookBtnTop {
    position: absolute;
    z-index: 999999;
    right: 15px;
    top: 15px;
    background: #0171ce;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
    border-radius: 4px;
    width: 130px;
    text-align: center;
  }

  .miniMenu {
    display: none;
  }
}

.homeTexts {
  margin-top: 70px;
  margin-bottom: 70px;
  text-align: center;
}

.homeInitialMesg {
  color: #858585;
  font-size: 1.2em;
  text-align: center;
}
