.vw-confirmation {
  .succesfull-letters {
    margin-top: 2rem;
    color: #272a40;
    font-family: $font-arial;
    @include respond-to(large) { font-size: 2.6rem; }
  }
  .intro p {
    color: #707070;
  }
  .order-summary {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
    h5 {
      color: #22bcee;
      border-bottom: 3px solid #272a40;
      line-height: 2;
      text-align: left;
      font-weight: 700;
      margin-bottom: 2rem;
      @include respond-to(large) { font-size: 2.6rem; }
    }
  }

  .pax-holder {
    width: 100%;
    display: block;
    margin: 0 auto;
    @include respond-to(xlarge) {
      max-width: 90%;
    }
  }

  .trip-info {
    background: white;
    padding: 1.5rem 0;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
    p {
      font-size: 18px;
      line-height: 1.2;
      margin: 0 0 10px 0;
      vertical-align: top;
    }
    small {
      display: block;
      color: color(blue, lighter-1);
      font-size: 12px;
      &.trip-type { color: black; }
    }

    .trip { display: block; }

    .date {
      display: inline-block;
      font-size: 12px;
      margin: 0 8px;
      span {
        display: block;
        color: color(blue, lighter-1);
      }
    }

    @include respond-to(large) {
      p { margin: 0 0 10px 0; }
      .date,
      .trip {
        display: inline-block;
        text-align: left;
      }

      .trip { margin-right: 5rem; }
    }
  }

  .passenger-info {
    background: #272a40;
    color: white;
    padding: 1.5rem 0;
    font-weight: bolder;
    text-transform: uppercase;
    .title {
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      margin: 0;
    }

    .dotted-divider {
      width: 90%;
      height: 1px;
      display: block;
      margin: 0 auto;
      border: 1px dashed white;
    }

    .passenger {
      background: #2f334e;
      font-size: 12px;
      padding: 1.5rem .5rem;
      margin: 1.5rem auto;
      text-align: center;
      p { margin: 0; }
      .name {
        font-size: 1.8rem;
        span {
          display: block;
          margin: .5rem 1rem;
        }
        span.paxclass {
          background: color(blue, lighter-1);
          font-size: 9px;
          padding: 0 .5rem;
          display: inline-block;
          vertical-align: middle;
        }
      }
      @include respond-to(large) {

        display: block;
        .name span { display: inline-block; }
      }
      @include respond-to(xlarge) {
        vertical-align: top;
        margin: 1rem .5rem;
        .name { font-size: 1.5rem; }
        .name span { display: inline-block; }
      }
    }

    @include respond-to(xlarge) {
      text-align: left;
    }
  }
  .booking-number {
    font-size: 2.7rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2;
    margin: 2rem 0;
    span {
      display: block;
      font-size: 1.5rem;
    }
    &.pagadito-ref {
      font-size: 1.8rem;
      span { font-size: 1.5rem; }
    }
  }
  .total-info {
    padding: 1rem;
    p {
      font-size: 4rem;
      line-height: 1;
      color: #272a40;
      font-weight: 600;
      text-transform: uppercase;
      small,
      span {
        font-size: 1.5rem;
      }
      span {
        display: block;
        // color: color(blue, lighter-1);
      }
    }
  }
}

.reviewPageDetail { 
   margin-top: -30px;
    width: 100% !important;
}

.topPaddingConfirm {
  padding: 20px;
}