$font-gray: #9a9a9a;

.vw-payment {
  h3 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 2.5rem 0;
    span { color: color(blue, lighter-1); }
  }

  .inner-content { max-width: 55rem; }
  .pay-method {
    margin: 5rem 0 3rem  0 !important;
  }
  .item {
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.5;
    font-weight: 500;
    span {
      display: inline-block;
      &:first-child {
        width: 45%;
        color: $font-gray;
      }
    }
    // &.subtotal,
    // &.total { font-size: 2rem; }
    // &.total span:first-child {
    //   font-weight: normal;
    //   font-size: 3rem;
    //   color: black;
    // }
  }
  .rtnCheck {
    margin-right: .5rem;
  }

  .total {
    background: white;
    color: color(blue, lighter-1);
    font-size: 4.7rem;
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0;
    margin: 1rem 0;

    span {
      padding: 0 1rem;
      display: block;
      line-height: 1;

      &:first-child {
        font-size: 2rem;
      }
    }
  }

  .coupon-group {
      padding: 0;
      position: relative;
      * { vertical-align: middle; }
      text-align: right;
      button {
        position: absolute;
        right: 0;
      }
      input[type='text'] {
        font-size: 1.5rem;
        line-height: 1.7;
        width: 100%;
        font-weight: 400;
        @include respond-to(large) {
          width: 50%;
        }
      }
    }

  .payWith {
    padding: 3rem;
    background: white;
    text-align: center;
    img {
      width: 4.5rem;
      margin-right: 2rem;
    }
    .ib {
      width: 18rem;
      text-align: left;
      vertical-align: top;
      @include respond-to(large) {
        width: 40rem;
      }
    }
    h4 {
      font-size: 2rem;
      color: color(blue, lighter-1);
    }
    p { margin: 0; }
    color: $font-gray;
  }

  .hidden {
    display: none;
  }
  .rtn-container {
    text-align: left;

    .form-group {
      display: inline-block;
      margin: .5rem;
    }

    span {
      margin: 0 .5rem;
      color: black;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    margin-left: 3rem;
    border-style: solid;
    border-width: 20px 17.5px 0 17.5px;
    border-color: white transparent transparent transparent;
  }

  .pagadito {
    background: white;
    padding: 2rem;
    margin: 1rem 0 0 0;
    img { width: 15rem; }
    input[type='text'],
    input[type='password'] {
      background: #eaeaea;
      margin: 1rem 0;
      padding: 1rem;
      width: 100%;
      border: none;
      font-weight: 500;
      color: #999;

      @include respond-to(large) {
        width: 48%;
        &:last-child { float: right; }
      }
    }
  }

  .paynowBtn {
    margin: 2rem 0;
    text-transform: uppercase;
    max-width: 20rem;
  }
}
