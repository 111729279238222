/*
// Buttons
// ------------------------------------------------ */

/*==== Base ====*/
[class^="btn-"],
[class*=" btn-"] {
  @include button-size(
    $input-padding-y,
    $input-padding-x,
    2rem,
    $input-line-height,
    0
  );
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  user-select: none;
  transition: all 0.3s ease;

  &,
  &.active,
  &:active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: color(grey);
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
  }
}

//==== Alternate Variants ====//
.btn-default {
  @include button-variant(#fff, color(grey), transparent);
}
.btn-blue {
  @include button-variant(#fff, color(blue, lighter-1), transparent);
}
.btn-green {
  @include button-variant(#fff, color(green, darker-1), transparent);
}
.btn-orange {
  @include button-variant(#fff, color(orange), transparent);
}
.btn-red {
  @include button-variant(#fff, color(red), transparent);
}
.btn-black {
  @include button-variant(#fff, color(black, lighter-1), transparent);
}
.btn-clear-round {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 2.5rem;

  &:hover {
    background-color: #fff;
  }
}
.float-right {
  float: right;
}
.margin-right {
  margin-right: 10px;
}
.boarding-pass-txt {
  line-height: 25px;
  margin-right: 10px !important;
}
.search-traveler-select-info-txt {
  color: #787878;
  margin: 0;
}
.info-btn {
  color: #22bcee;
  cursor: pointer;
}

//==== Sizes ====//
.btn-xl {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
    map-deep-get($padding, "lg", "vertical"),
    map-deep-get($padding, "lg", "horizontal"),
    2.8rem,
    1.3333333,
    map-get($radius, lg)
  );
}
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
    map-deep-get($padding, "md", "vertical"),
    map-deep-get($padding, "md", "horizontal"),
    2rem,
    1.3333333,
    map-get($radius, md)
  );
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size(
    map-deep-get($padding, "sm", "vertical"),
    map-deep-get($padding, "sm", "horizontal"),
    1.4rem,
    1.5,
    map-get($radius, sm)
  );
}
.btn-xs {
  @include button-size(
    map-deep-get($padding, "xs", "vertical"),
    map-deep-get($padding, "xs", "horizontal"),
    1.4rem,
    1.5,
    map-get($radius, sm)
  );
}

//==== Button Link =====//
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    box-shadow: none;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: color(grey);
      text-decoration: none;
    }
  }
}

//==== Block button ====//
.btn-block {
  display: block;
  width: 100%;
  border-radius: 0;

  // Vertically space out multiple block buttons
  & + .btn-block {
    margin-top: 5px;

    @include respond-to(large) {
      margin-top: 0;
    }
  }
}

.promocodeBTN {
  margin-left: 10px;
  height: 30px;
  padding: 2px 12px;
}
