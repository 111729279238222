.no-results-jsx {
	text-align: center;
	font-size: 5rem;
	text-transform: uppercase;
	font-weight: 500;
	color: #22bcee;
	margin: 5rem 0;
    line-height: 1;
}
.tripsMessage {
  font-weight: 500;
}
