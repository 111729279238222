.vw {
  > .container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 6rem;
  }

  .is-title {
    font-size: 3.5rem;
    line-height: 1.5;
    margin: 2rem 0;
    border-bottom: 1px solid #c1c1c1;
  }
}
