$custom-gray: #8a8a8b;
@include respond-to(large) {
  .auto-height {
    height: fit-content;
    padding: 2.7rem 0;
  }
}

.result {
  background: white;
  margin: 1rem auto;
  .top {
    display: flex;
  }

  .topguest {
    display: flex;
    width: 80% !important;
    align-items: baseline;
  }
  .arrow {
    color: color(blue, lighter-1);
    display: flex;
    align-items: center;
    span {
      background: color(blue, lighter-1);
      height: 1px;
      width: 92%;
      display: none;
    }
    @include respond-to(medium) {
      width: 40%;
      span {
        display: inline-block;
      }
      i {
        margin-left: -1.1rem;
      }
    }
  }
  .detail {
    width: 90%;
    padding: 0.5rem 1.5rem 0.5rem 1rem;

    @include respond-to(xlarge) {
      width: 68%;
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  .route {
    margin: 0.5rem 0;
    text-transform: uppercase;
    width: 100%;

    @include respond-to(xlarge) {
      width: 45%;
    }
  }

  .full-size {
    @include respond-to(xlarge) {
      width: 100%;
    }
  }
  h3 {
    color: $custom-gray;
    font-size: 1.4rem;
    text-align: left;
    line-height: 1.6;
    border-bottom: 2px solid #f4f4f4;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hour {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
    small {
      font-size: 1.2rem;
    }
    span {
      display: block;
      color: $custom-gray;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  .price {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    justify-content: center;
    width: 40%;
    text-transform: uppercase;
    margin-left: auto;
    border-left: 2px dashed #c1c1c1;
    img {
      width: 3rem;
    }
    p {
      margin: 1rem 0;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1.3;
    }
    small {
      display: block;
      font-size: 0.8rem;
      color: $custom-gray;
    }
    .btn-select {
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    & div {
      width: 80%;
    }

    @include respond-to(xlarge) {
      padding: 1.5rem 0;
      p {
        font-size: 3rem;
      }
      small {
        font-size: 1rem;
      }
    }
  }
  .hr-divider {
    height: 2px;
    display: block;
    background: #f4f4f4;
  }
  .btn-class {
    width: 100%;
    padding: 3rem 0.3rem;
    margin: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;

    @include respond-to(large) {
      width: auto;
      margin: 0 2rem 0 0;
      padding: 3rem 1rem;
    }
  }
  .total {
    margin: 0.5rem 0;
    font-size: 2rem;
    color: $custom-gray;
    font-weight: 500;
    small {
      font-size: 1.1rem;
    }

    @include respond-to(large) {
      margin: 0;
    }
  }
  .result-footer {
    padding: 0 1rem 0.1rem;
    display: block;

    @include respond-to(large) {
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
    }
  }
}
