.overlay {
  background: rgba(0, 0, 0, .75);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 1.2rem;

}
.overlayCenter {
  align-items: center;
}
.modal {
  max-width: 800px;
  position: relative;
  padding: 1.2rem;
  background: #fff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .25);
}
.closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
}
.transitionEnter {
  opacity: .01;
}
.transitionEnterActive {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(.23, 1, .32, 1);
}
.transitionExit {
  opacity: 1;
}
.transitionExitActive {
  opacity: .01;
  transition: opacity 500ms cubic-bezier(.23, 1, .32, 1);
}

.ModalCustomStyles {
  h2 {
    font-size: 2rem !important;
  }
}

.OverlayCustomStyles {
  align-items: flex-start !important;
}
